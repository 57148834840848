import React, { Component, useEffect, useState, useMemo } from 'react'
import BookingReserveExpiredModal from './BookingReserveExpiredModal.jsx'
import consumer from '../channels/consumer'
import { DateTime } from 'luxon'

//add to Chris' branch
const useCountdown = (endDate) => {
  const end = useMemo(() => DateTime.fromISO(endDate), [endDate])

  const [countDown, setCountDown] = useState(null)

  useEffect(() => {
    if (!end.isValid) return setCountDown(null)

    const interval = setInterval(() => {
      const now = DateTime.local()
      const remaining = end.diff(now)

      setCountDown(remaining)
    }, 1000)

    return () => clearInterval(interval)
  }, [end])

  return countDown
}

const TimeLimitCountdown = ({
  booking,
  runningIcon,
  expiredIcon,
  restartPath,
}) => {
  const remaining = useCountdown(booking.expires_at)

  const bookingState = useMemo(
    (prev) => {
      if (prev === 'EXPIRED') return prev
      if (!remaining) return 'IDLE'
      if (remaining.as('minutes') > 0) return 'RUNNING'
      return 'EXPIRED'
    },
    [remaining],
  )

  return (
    <div>
      {bookingState === 'RUNNING' && (
        <>
          <img src={runningIcon} className="emoji" />
          Timen er reservert i{' '}
          {remaining && remaining.toFormat('mm:ss')} minutter.
        </>
      )}
      {bookingState === 'EXPIRED' && (
        <>
          {' '}
          <BookingReserveExpiredModal
            restartPath={restartPath}
            isOpen={true}
          />{' '}
          <div>
            <img src={expiredIcon} className="emoji" />
            Tiden har dessverre utløpt
          </div>
        </>
      )}
      {bookingState === 'IDLE' && <></>}
    </div>
  )
}

export default TimeLimitCountdown
