import React, { Component } from 'react'
import PropTypes from 'prop-types'

class InfoBox extends Component {
  static propTypes = {
    showReadMoreButton: PropTypes.bool,
    className: PropTypes.string.isRequired,
    buttonClass: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.state = {
      showInfoBox: false,
    }
  }

  showInfoBox = () => {
    this.setState({ showInfoBox: true })
  }

  button() {
    return (
      <button
        className={`info-box__button--link ${this.props.buttonClass}`}
        onClick={this.showInfoBox}
      >
        Les mer
      </button>
    )
  }

  infoBox() {
    return (
      <div className={`info-box ${this.props.className}`}>
        <h2 className="info-box__header">Om tjenesten</h2>
        <p className="info-box__text">
          Hjemmelegene tilbyr behandling når det passer deg, i
          hjemmets trygge rammer. Dette lover vi:
        </p>
        <ul className="info-box__list">
          <li className="interest-notification__info-block-list-item">
            Kvalitetssikret og erfarent helsepersonell
          </li>
          <li className="info-box__list-item">
            God tid med lege, der du er
          </li>
          <li className="info-box__list-item">
            Fast pris. Alt av nødvendig utstyr, medisiner og prøver er
            inkludert
          </li>
          <li className="info-box__list-item">
            Legen kan utstede resepter, henvisninger og sykemelding om
            aktuelt
          </li>
        </ul>
      </div>
    )
  }

  render() {
    if (
      !this.props.showReadMoreButton ||
      (this.props.showReadMoreButton && this.state.showInfoBox)
    ) {
      return this.infoBox()
    }

    return this.button()
  }
}

export default InfoBox
